import styled from "styled-components"

export const BreadCrumbFlex = styled.div`
  margin-top: 90px;
  padding-bottom: 96px;
  font-family: Roboto;
  position: absolute;
  z-index: 100;
  padding: 24px 15px;
  left: 50%;
  z-index: 9999;
  width: 100%;
  max-width: 1170px;
  transform: translate(-50%, 0%);
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  a {
    text-decoration: none;
  }
  img {
    padding: 0 10px;
    transform: scale(80%) translateY(2px);
  }
  span {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    transition: color 200ms ease;
    &:hover {
      color: #dddddd;
    }
  }
  @media (max-width: 992px) {
    max-width: 770px;
  }
  @media (max-width: 768px) {
    max-width: 506px;
  }
`
export const IconImg = styled.div`
  img {
    &:last-child {
      display: none;
    }
  }
`

export const HeadingWrapper = styled.div`
  background-size: cover;
  background-position: center;
  padding: 210px 60px 302px;
  font-family: "Roboto";
  position: relative;
  &:before {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
  }
  @media (max-width: 992px) {
    padding: 160px 40px 302px;
  }
  @media (max-width: 768px) {
    padding: 160px 40px 220px;
  }
  @media (max-width: 576px) {
    padding: 168px 20px 160px;
  }
  .background-image {
    position:absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
    top: 0;
    left: 0;
  }
  .home-hero--container {
    position: relative;
    z-index: 10;
  }
  .home-hero--copy-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .home-hero--heading {
      font-weight: 900;
      font-size: 50px;
      line-height: 64px;
      text-align: center;
      color: #ffffff;
      @media (max-width: 768px) {
        font-size: 44px;
        line-height: 48px;
      }
      @media (max-width: 576px) {
        font-size: 38px;
        line-height: 44px;
      }
    }
    .home-hero--description {
      font-size: 22px;
      line-height: 30px;
      text-align: center;
      color: #e4e7ec;
      margin: 16px 0 0;
      max-width: 770px;
      @media (max-width: 992px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
    .home-hero--buttons {
      margin: 32px 0 0;
      display: flex;
      gap: 15px;
      align-items: center;
      @media (max-width: 576px) {
        width: 100%;
        .button-wrapper {
          width: 100%;
          button {
            width: 100%;
          }
        }
      }
      @media (max-width: 576px) {
        flex-direction: column;
      }
      .home-hero--button-play {
        background: transparent;
        color: white;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration-line: underline;
        color: #ffffff;
        outline: none;
        border: none;
        height: 54px;
        width: 147px;
        margin-left: 16px;
        cursor: pointer;
        transition: opacity 0.2s ease-in;
        img {
          width: 38px;
          height: 38px;
          margin-right: 10px;
        }
        &:hover {
          opacity: 0.7;
        }
        @media (max-width: 576px) {
          margin: 16px 0 0;
        }
      }
    }
  }
`

export const ThumbnailWrapper = styled.div`
  position: relative;
  .image-content {
    width: 100%;
    max-height: 375px;
    border-radius: 12px;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
    img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-height: 375px;
      object-fit: cover;
      object-position: center;
      filter: drop-shadow(0px 8px 80px rgba(45, 40, 77, 0.2));
      border-radius: 12px;
    }
    .emptyImg {
      width: 100%;
      max-height: 800px;
      min-height: 450px;
      background: #d4d9e2;
      @media (max-width: 992px) {
        width: 100%;
        min-width: auto;
        min-height: auto;
      }
    }
  }
`

export const VideoSectionWrapper = styled.div`
  z-index: 50;
  margin: 0 auto;
  transform: translateY(-50%);
  max-width: 1170px;
  margin-bottom: -180px;
  @media (max-width: 1170px) {
    max-width: 992px;
    padding: 0 35px;
    margin-bottom: -180px;
  }
  @media (max-width: 992px) {
    max-width: 770px;
    padding: 0 35px;
    margin-bottom: -180px;
  }
  @media (max-width: 768px) {
    max-width: 506px;
    padding: 0 20px;
    margin-bottom: -120px;
  }
`
