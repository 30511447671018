import React from "react"
import { Button, Heading } from "@atoms"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Link } from "gatsby"
import OptimizedImage from "../../utils/optimizedImage"
import { 
  BreadCrumbFlex, 
  IconImg, 
  HeadingWrapper, 
  ThumbnailWrapper, 
  VideoSectionWrapper 
} from "./styles/HeroImageFloat.styled"

const HeroImageFloat = ({ component }) => {
  const {
    callsToAction,
    background,
    featuredImage,
    heading,
    headingSize,
    subheading,
    videoThumbnail,
  } = component
  
  return (
    <div>
      <BreadCrumbFlex>
        <Link href="/">
          <span
            style={{
              fontWeight: 400,
              textDecoration: "none",
              color: "white",
              transition: "color 200ms ease",
            }}
          >
            Home
          </span>
        </Link>
        <IconImg>
          <img src="/icons/chevron-right-white.svg" alt="Breadcrumb Chevron" />
          <img src="/icons/chevron-right-black.svg" alt="Breadcrumb Chevron" />
        </IconImg>
        <span>{heading}</span>
      </BreadCrumbFlex>
      <HeadingWrapper
        component={component}
        data-comp={HeroImageFloat.displayName}
        backgroundImage={component?.featuredImage}
      >
        <OptimizedImage className="background-image" loading="eager" image={featuredImage?.gatsbyImageData} src={featuredImage?.file?.url} alt=""/>
        <div className="home-hero--container">
          <div className="home-hero--copy-container">
            {heading && (
              <Heading
                background={background}
                headingSize={headingSize}
                className="home-hero--heading"
              >
                {heading}
              </Heading>
            )}
            {subheading?.raw && (
              <p className="home-hero--description">
                {documentToReactComponents(
                  JSON.parse(component?.subheading?.raw)
                )}
              </p>
            )}
            <div className="home-hero--buttons">
              {callsToAction &&
                component.callsToAction.map((cta, index) => {
                  return (
                    <div className="button-wrapper">
                      <Button
                        ctaLink={cta?.link}
                        ctaVariant={index === 0 ? "secondary" : cta?.variant}
                        ctaType={cta?.type || 'standard'}
                        {...cta}
                      >
                        {cta.label}
                      </Button>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      </HeadingWrapper>
      <VideoSectionWrapper>
        <ThumbnailWrapper>
          <figure className="image-content">
            {videoThumbnail?.file?.url ? (
              <div>
                <OptimizedImage image={videoThumbnail?.gatsbyImageData} src={videoThumbnail?.file.url} alt={heading || "video thumbnail"}/>
              </div>
            ) : (
              <div className="emptyImg" />
            )}
          </figure>
        </ThumbnailWrapper>
      </VideoSectionWrapper>
    </div>
  )
}

export default HeroImageFloat
HeroImageFloat.displayName = "HeroImageFloat"
